import React from "react";
import SectionTitle from "../common/SectionTitle";

const FeatureImgSix = () => {
  return (
    <>
      <section className="feature-section-two ptb-120">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="feature-content-wrap">
                <SectionTitle
                  title="Tại sao chọn chúng tôi"
                  description=""
                  leftAlign
                />
                <ul className="list-unstyled mb-0">
                  <li className="d-flex align-items-start mb-4">
                    <div className="icon-box bg-primary rounded me-4">
                      <i className="fas fa-bezier-curve text-white"></i>
                    </div>
                    <div className="icon-content">
                      <p>
                        Chính xác và thời gian nhận diện nhanh Thuật toán được
                        huấn luyện trên một bộ dataset lớn vì vậy có độ chính
                        xác cao và có kết quả nhanh. Người dùng sẽ trải nghiệm
                        được một sản phẩm AI sáng tạo và không phải tốn quá
                        nhiều thời gian và can thiệp của con người.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-start mb-4">
                    <div className="icon-box bg-danger rounded me-4">
                      <i className="fas fa-fingerprint text-white"></i>
                    </div>
                    <div className="icon-content">
                      <p>
                        Dễ dàng tuỳ chỉnh để phù hợp với giao diện và yêu cầu
                        của doanh nghiệp Tuỳ chỉnh và nhu cầu sử dụng mà giải
                        pháp của chúng tôi có thể đáp ứng.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-7">
              <div className="feature-img-wrap">
                <img
                  src="assets/img/page-five/why-choose-us.jpg"
                  alt="feature"
                  className="img-fluid rounded-custom"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgSix;
