import React from "react";
import PageHeader from "../../components/common/PageHeader";
import PageMeta from "../../components/common/PageMeta";
import MainProduct from "./MainProduct";
import Navbar from "../../layout/Header/Navbar";
import FooterOne from "../../layout/Footer/FooterOne";
import Layout from "../../layout/Layout";

const Product = () => {
  return (
    <Layout>
      <Navbar navDark />
      <PageMeta title="Sản phẩm và giải pháp" />
      <PageHeader
        title="Sản phẩm và Giải pháp"
        desc="Đội ngũ chúng tôi cố gắng sử dụng những công nghệ hiện đại và một quy trình làm việc được chuẩn hoá để trao tới tay khách hàng những sản phẩm và giải pháp tốt nhất."
      />
      <MainProduct footerLight />
      <FooterOne />
    </Layout>
  );
};

export default Product;
