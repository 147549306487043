import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${
          navDark ? "position-absolute " : ""
        } w-100 position-absolute `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src="assets/img/home/logo.png"
                alt="logo"
                className="img-fluid logo-white"
                style={{ width: 170 }}
              />
              <img
                src="assets/img/home/logo.png"
                alt="logo"
                className="img-fluid logo-color"
                style={{ width: 170 }}
              />
            </Link>
            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-end">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li>
                  <Link to="/" className="nav-link fw-bold">
                    Trang chủ
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="nav-link fw-bold">
                    Về chúng tôi
                  </Link>
                </li>
                <li>
                  <Link to="/product-and-solution" className="nav-link fw-bold">
                    Sản phẩm và giải pháp
                  </Link>
                </li>
                <li>
                  <Link to="/blogs" className="nav-link fw-bold">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className="nav-link fw-bold">
                    Liên hệ
                  </Link>
                </li>
              </ul>
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
