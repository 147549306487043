import React from "react";
import MainPage from "./routers/MainPage";
import MonitorPage from "./routers/MonitorPage";

// Swiper CSS
import "swiper/css";
import "swiper/css/navigation";

//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";

//Modal Video
import "react-modal-video/scss/modal-video.scss";

function App() {
  const host = window.location.host.replaceAll("www.", "");

  if (host.split(".")[0] === "monitor") {
    return (
      <>
        <MonitorPage />
      </>
    );
  } else {
    return (
      <>
        <MainPage />
      </>
    );
  }
}

export default App;
