import React, { Component } from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroOne from "./HeroOne";
import FeatureImg from "../../components/features/FeatureImg";
import PageMeta from "../../components/common/PageMeta";
import FooterOne from "../../layout/Footer/FooterOne";
import WorkProcessFour from "../../components/work-process/WorkProcessFour";
import TestimonialHome from "./TestimonialHome";
import LatestBlogHome from './LatestBlogHome';
import Service from './Service';

class Home extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title="Trang chủ" />
        <Navbar navDark />
        <HeroOne />
        <FeatureImg />
        <Service cardDark />
        <WorkProcessFour />
        <TestimonialHome dark />
        <LatestBlogHome />
        <FooterOne footerGradient />
      </Layout>
    );
  }
}

export default Home;
