import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Công ty cổ phần &amp; dịch vụ Achitek</title>
      <meta name="description" content="Cam kết vì một tương lai tốt đẹp hơn" />
    </Helmet>
    <App />
  </>
);
