import React, { Component } from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroOne from './HeroOne';
import FeatureImg from '../../components/features/FeatureImg';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import GameServices from "../../components/services/GameServices";
import WorkProcessFour from '../../components/work-process/WorkProcessFour';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';

class HomeSassOne extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title='Trang chủ' />
        <Navbar navDark />
        <HeroOne />
        <FeatureImg />
        <GameServices />
        <WorkProcessFour />
        <TestimonialTwo bgWhite/>
        <FooterOne footerGradient />
      </Layout>
    );
  }
}

export default HomeSassOne;
