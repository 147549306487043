import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import HeroTitle from '../../components/common/HeroTitle';

const HeroSeven = () => {
  // Modal Video
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section
        className='hero-section ptb-120 text-white bg-gradient'
        style={{
          background: "url('assets/img/hero-dot-bg.png')no-repeat center right",
        }}
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-10'>
              <div className='hero-content-wrap mt-5'>
                <HeroTitle
                  title='Phân tích &amp; khai phá dữ liệu'
                  desc='Thúc đẩy các quyết định kinh doanh tốt hơn, cho phép tăng doanh thu cải thiện hiệu quả trong kinh doanh.'
                />
                <div className='action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex'>
                  <Link to='/request-demo' className='btn btn-danger me-3'>
                    Yêu cầu demo
                  </Link>
                  <ModalVideo
                    channel='youtube'
                    isOpen={isOpen}
                    videoId='hAP2QF--2Dg'
                    onClose={() => setOpen(false)}
                  />
                  <Link
                    to='#'
                    className='text-decoration-none d-inline-flex align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0'
                    onClick={() => setOpen(true)}
                  >
                    {' '}
                    <i className='fas fa-play'></i> Xem demo{' '}
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-8 mt-5'>
              <div className='hero-img position-relative circle-shape-images'>
                {/* <!--animated shape start--> */}
                <ul className='position-absolute animate-element parallax-element circle-shape-list'>
                  <li className='layer' data-depth='0.02'>
                    <img
                      src='assets/img/shape/circle-1.svg'
                      alt='shape'
                      className='circle-shape-item type-1 hero-1'
                    />
                  </li>
                  <li className='layer' data-depth='0.04'>
                    <img
                      src='assets/img/shape/circle-1.svg'
                      alt='shape'
                      className='circle-shape-item type-2 hero-1'
                    />
                  </li>
                  <li className='layer' data-depth='0.04'>
                    <img
                      src='assets/img/shape/circle-1.svg'
                      alt='shape'
                      className='circle-shape-item type-3 hero-1'
                    />
                  </li>
                  <li className='layer' data-depth='0.03'>
                    <img
                      src='assets/img/shape/circle-1.svg'
                      alt='shape'
                      className='circle-shape-item type-4 hero-1'
                    />
                  </li>
                  <li className='layer' data-depth='0.03'>
                    <img
                      src='assets/img/shape/circle-1.svg'
                      alt='shape'
                      className='circle-shape-item type-5 hero-1'
                    />
                  </li>
                </ul>
                {/* <!--animated shape end--> */}
                <img
                  src='assets/img/screen/animated-screen-5.svg'
                  alt='hero img'
                  className='img-fluid position-relative z-5'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSeven;
