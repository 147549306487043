import React from "react";

export default function EventAbout() {
  return (
    <section
      className="event-about"
      style={{
        background:
          "url('assets/img/event/about-bg.jpg')no-repeat center center/cover",
      }}
    >
      <div className="container" style={{ marginTop: 150 }}>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="ev-ab-img position-relative ms-0 ms-lg-5">
              <img
                src="assets/img/page-fifteen/banner.png"
                className="img-fluid z-5 position-relative"
                alt="men"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="p-3">
              <span
                className="text-pink fw-bold mb-3 d-inline-block"
                style={{ fontSize: 40 }}
              >
                Về Achitek
              </span>
              <h2 className="mb-4">Cam kết một tương lai tốt hơn</h2>
              <p style={{ fontSize: 18 }}>
                Achitek hội tụ rất nhiều các chuyên gia trong lĩnh vực nghiên
                cứu, xây dựng và phát triển phần mềm, ứng dụng trí tuệ nhân tạo
                và dữ liệu lớn. Cùng nhau chúng tôi Achitek cam kết xây dựng một
                tương lai tốt đẹp hơn bằng cách áp dụng các công nghệ tiên tiến,
                hiện đại nhất.
              </p>
            </div>
          </div>
        </div>
        <ul className="elements list-unstyled d-none d-xl-block">
          <li>
            <img src="assets/img/event/star.png" alt="star" />
          </li>
          <li>
            <img
              src="assets/img/event/human-shape.png"
              className="img-fluid"
              alt="human"
            />
          </li>
          <li></li>
        </ul>
      </div>
    </section>
  );
}
