import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeSassOne from "../themes/index1/HomeSassOne";
import NotFoundScreen from "../components/others/NotFoundScreen";
import AboutUs from "../themes/index15/AboutUs";
import ScrollToTop from "../components/common/ScrollToTop";
import HomeSoftApplication from "../themes/index5/HomeSoftApplication";
import HomeDataAnalysis from "../themes/index7/HomeDataAnalysis";
import Blogs from "../pages/blog/Blogs";
import HomeNine from "../themes/index9/HomeNine";
import SingleBlog from "../pages/blog/SingleBlog";

function MonitorPage() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeSassOne />} />
        <Route
          exact
          path="/plate-recognition"
          element={<HomeSoftApplication />}
        />
        <Route exact path="/bi-solution" element={<HomeDataAnalysis />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/face-recognition" element={<HomeNine />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog-single" element={<SingleBlog />} />
        <Route path="/404" element={<NotFoundScreen />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MonitorPage;
