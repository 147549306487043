import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/common/SectionTitle";

const Service = ({ cardDark }) => {
  return (
    <>
      <section
        className={`feature-section ptb-120 ${
          cardDark ? "bg-dark" : "bg-light"
        }`}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ boxSizing: "content-box" }}
          >
            <div className="col-lg-6 col-md-10">
              {cardDark ? (
                <SectionTitle
                  title="Các sản phẩm và giải pháp"
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle title="Các sản phẩm và giải pháp" centerAlign />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="feature-grid"
                // style={{ gridTemplateColumns: "1fr 1fr" }}
              >
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? "bg-custom-light promo-border-hover border border-2 border-light text-white"
                      : "bg-white"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="feature-content">
                    <h3 className="h5 mb-3">Các ứng dụng phần mềm</h3>
                    <p className="mb-0">
                      Tư vấn, xây dựng ứng dụng web và mobile cho khách hàng sử
                      dụng công nghệ web mới nhất. Bên cạnh đó, chúng tôi cũng
                      tư vấn và cung cấp các giải pháp tự động hoá quy trình làm
                      việc cho các doanh nghiệp.
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link
                      to="/"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Xem chi tiết <i className="far fa-arrow-right"></i>
                    </Link>
                    <Link
                      to="/contact-us"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Liên hệ <i className="far fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? "bg-custom-light promo-border-hover border border-2 border-light text-white"
                      : "bg-white"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="feature-content">
                    <h3 className="h5 mb-3">Các sản phẩm AI</h3>
                    <p className="mb-0">
                      Tư vấn và phát triển các sản phẩm AI dựa trên nhu cầu của
                      doanh nghiệp. Các sản phẩm liên quan đến xử lý hình ảnh,
                      xử lý âm thanh và chatGPT.
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link
                      to="/product-ai"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Xem chi tiết <i className="far fa-arrow-right"></i>
                    </Link>
                    <Link
                      to="/contact-us"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Liên hệ <i className="far fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? "bg-custom-light promo-border-hover border border-2 border-light text-white"
                      : "bg-white"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="feature-content">
                    <h3 className="h5 mb-3">Giải pháp dữ liệu lớn</h3>
                    <p className="mb-0">
                      Tư vấn và xây dựng hệ thống báo cáo quản trị (BI) để cung
                      cấp cái nhìn tổng thể về doanh nghiệp. Chúng tôi cũng cung
                      cấp các giải pháp nền tảng dữ liệu (data platform) và giải
                      pháp chuyển đổi dữ liệu (data migration).
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link
                      to="/"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Xem chi tiết <i className="far fa-arrow-right"></i>
                    </Link>
                    <Link
                      to="/contact-us"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Liên hệ <i className="far fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? "bg-custom-light promo-border-hover border border-2 border-light text-white"
                      : "bg-white"
                  }`}
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="feature-content">
                    <h3 className="h5 mb-3">Các sản phẩm blockchain và NFT</h3>
                    <p className="mb-0">
                      Tư vấn và xây dựng các sản phẩm sử dụng nền tảng
                      blockchain, các game NFT.
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link
                      to="/"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Xem chi tiết <i className="far fa-arrow-right"></i>
                    </Link>
                    <Link
                      to="/contact-us"
                      className="link-with-icon text-decoration-none mt-3"
                    >
                      Liên hệ <i className="far fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
