import React from "react";

const ContactBox = () => {
  return (
    <>
      <section className="contact-promo ptb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
              <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                <span className="fad fa-comment-alt-lines fa-3x text-primary"></span>
                <div className="contact-promo-info mb-4">
                  <h5>Chat với chúng tôi</h5>
                  <p>
                    Giải đáp thắc mắc từ <strong>thứ Hai tới thứ Sáu</strong> từ
                    <strong> 9h sáng đến 5h chiều.</strong>
                  </p>
                </div>
                <a
                  href="mailto:contact@achitek.net"
                  className="btn btn-link mt-auto"
                >
                  Chat với chúng tôi
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
              <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                <span className="fad fa-envelope fa-3x text-primary"></span>
                <div className="contact-promo-info mb-4">
                  <h5>Email chúng tôi</h5>
                  <p>
                    Để lại một email tại <strong>contact@achitek.net </strong>
                    và bạn sẽ được trả lời trong vòng 24h
                  </p>
                </div>
                <a
                  href="mailto:contact@achitek.net"
                  className="btn btn-primary mt-auto"
                >
                  Email chúng tôi
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
              <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                <span className="fad fa-phone fa-3x text-primary"></span>
                <div className="contact-promo-info mb-4">
                  <h5>Gọi trực tiếp</h5>
                  <p>
                    Gọi cho chúng tôi từ <strong>thứ Hai tới thứ Sáu</strong> từ
                    <strong> 9h sáng tới 5h chiều.</strong>
                  </p>
                </div>
                <a href="tel:0964167037" className="btn btn-link mt-auto">
                  0964 167 037
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactBox;
