import React from "react";

export default function NewsLetterTwo() {
  return (
    <section className="digi-news-letter pt-120">
      <div className="container">
        <div
          className="text-dark rounded-custom p-4 p-md-5 p-lg-5"
          style={{ backgroundColor: "#f5f7fc" }}
        >
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="digi-newsletter">
                <div>
                  <span className="span-arrow text-pink">
                    Giải pháp
                  </span>
                  <img src="assets/img/arro-right.svg" alt="arrow" />
                </div>
                <h2>Độ chính xác cao &amp; tốc độ nhanh</h2>
                <p>
                  Phần mềm chấm công bằng khuôn mặt sử dụng thuật toán học sâu
                  (deep learning) tối ưu nhất cho việc chấm công, điểm danh bằng
                  khuôn mặt. Giải pháp được tối ưu cho định danh khuôn mặt ở nơi
                  công sở giúp các doanh nghiệp nâng cao trải nghiệm và tiết
                  kiệm thời gian của nhân viên.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="dg-news-img text-end">
                <img
                  src="assets/img/page-nine/high-precision.jpg"
                  className="img-fluid text-end"
                  alt="pie"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
