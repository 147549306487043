import React from 'react';

const ContactFormTwo = () => {
  return (
    <>
      <section
        className='contact-us-form pt-60 pb-120'
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-6 col-md-8'>
              <div className='section-heading'>
                <h2>Trao đổi với đội ngũ phát triển</h2>
                <p>
                  Để lại câu hỏi cho chúng tôi ở đây. Chúng tôi sẽ liên lạc với bạn ngay sau khi nhận được lời nhắn.
                </p>
              </div>
              <form action='#' className='register-form'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label htmlFor='firstName' className='mb-1'>
                      Họ <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='firstName'
                        required
                        placeholder='Họ'
                        aria-label='Họ'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 '>
                    <label htmlFor='lastName' className='mb-1'>
                      Tên
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='lastName'
                        placeholder='Tên'
                        aria-label='Tên'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='phone' className='mb-1'>
                      Số điện thoại <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='phone'
                        required
                        placeholder='Số điện thoại'
                        aria-label='Số điện thoại'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='email' className='mb-1'>
                      Email<span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='email'
                        className='form-control'
                        id='email'
                        required
                        placeholder='Email'
                        aria-label='Email'
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <label htmlFor='yourMessage' className='mb-1'>
                      Câu hỏi <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <textarea
                        className='form-control'
                        id='yourMessage'
                        required
                        placeholder='Chúng tôi có thể giúp gì cho bạn'
                        style={{ height: '120px' }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary mt-4'>
                  Gửi
                </button>
              </form>
            </div>
            <div className='col-lg-5 col-md-10'>
              <div className='contact-us-img'>
                <img
                  src='assets/img/contact-us-img-2.svg'
                  alt='contact us'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
