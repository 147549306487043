import React from 'react';
import BlogDetails from '../../components/blog/BlogDetails';
import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const SingleBlog = () => {
  return (
    <Layout>
      <PageMeta title="Blog Details - Software &amp; IT Solutions HTML Template" />
      <Navbar navDark />
      <PageHeader title="Why customer retention is the ultimate growth strategy" />
      <BlogDetails />
      <FooterOne />
    </Layout>
  );
};

export default SingleBlog;
