import React from "react";
import { Link } from "react-router-dom";
import HeroTitle from "../../components/common/HeroTitle";

const HeroFive = () => {
  return (
    <>
      <section
        className="hero-section ptb-120 min-vh-100 d-flex align-items-center bg-dark text-white position-relative overflow-hidden"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <HeroTitle
                  title="Nhận diện biển số xe Achitek"
                  desc="Phát hiện nhanh chóng, chính xác biển số xe ô tô và xe máy."
                />

                <div className="action-btns mt-5">
                  <Link to="/request-for-demo" className="btn btn-primary me-3">
                    Yêu cầu demo
                  </Link>
                  <Link to="/about-us" className="btn btn-outline-light">
                    Xem demo
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 mt-5 mt-lg-0">
              <div className="animated-img-wrap">
                <img
                  src="assets/img/page-five/banner.jpg"
                  alt="shape"
                  className="position-relative img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroFive;
