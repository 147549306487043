import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import HeroSix from './HeroSix';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
// import FeatureImgThree from '../../components/features/FeatureImgThree';
import WorkProcessFour from '../../components/work-process/WorkProcessFour';
// import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureTwo from '../../components/features/FeatureTwo';
import WorkProcessFive from '../../components/work-process/WorkProcessFive'

const HomeStartup = () => {
  return (
    <Layout>
      <PageMeta title='Web &amp; App development' />
      <Navbar navDark posAbsolute />
      <HeroSix />
      <WorkProcessFive />
      {/* <FeatureImgThree /> */}
      {/* <FeatureImgEight /> */}
      <FeatureTwo />
      <WorkProcessFour />
      <FooterOne footerGradient />
    </Layout>
  );
};

export default HomeStartup;
