import React from "react";
import PageMeta from "../../components/common/PageMeta";
import FeatureImgContent from "../../components/feature-img-content/FeatureImgContent";
import FeatureImgContentTwo from "../../components/feature-img-content/FeatureImgContentTwo";
import FeatureFour from "../../components/features/FeatureFour";
import HeroSeven from "./HeroSeven";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";

const HomeDataAnalysis = () => {
  return (
    <Layout>
      <PageMeta title="Phân tích kinh doanh - BI" />
      <Navbar navDark />
      <HeroSeven />
      <FeatureFour />
      <FeatureImgContentTwo
        title={"Phân tích bán hàng"}
        description={
          "Quan trọng để biết chính xác năng suất bán hàng và tập sản phẩm, khách hàng, khu vực và các kênh tiềm năng."
        }
        img={"assets/img/page-seven/sales-analysis.jpg"}
      />
      <FeatureImgContent
        title={"Phân tích tài chính"}
        description={
          "Xác định doanh thu và lợi nhuận phân tích ra những tác nhân ảnh hưởng đến hiệu suất của doanh nghiệp."
        }
        img={"assets/img/page-seven/financial-analysis.jpg"}
      />
      <FeatureImgContentTwo
        title={"Phân tích vận chuyển"}
        description={
          "Các công ty vận tải thường bị cản trở trong việc lập kế hoạch, quản lý và đánh giá dịch vụ của họ do phải phụ thuộc nhiều vào các hệ thống thu thập dữ liệu thủ công tốn kém và không đáng tin cậy."
        }
        img={"assets/img/page-seven/shopping-analysis.jpg"}
      />
      <FeatureImgContent
        title={"Phân tích kho hàng"}
        description={
          "Việc sử dụng hiệu quả dữ liệu là rất quan trọng để quản lý hiệu quả hàng tồn kho, giúp tối đa hóa lợi nhuận và tăng sự hài lòng của khách hàng."
        }
        img={"assets/img/page-seven/warehouse-analysis.jpg"}
      />
      <FooterOne />
    </Layout>
  );
};

export default HomeDataAnalysis;
