import React from "react";
import PageMeta from "../../components/common/PageMeta";
import FeatureImgSeven from "../../components/features/FeatureImgSeven";
import FeatureImgSix from "../../components/features/FeatureImgSix";
import HeroFive from "./HeroFive";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";

const HomeSoftApplication = () => {
  return (
    <Layout extraClass="overflow-hidden">
      <PageMeta title="Nhận diện biển số xe" />
      <Navbar />
      <HeroFive />
      <FeatureImgSeven />
      <FeatureImgSix />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default HomeSoftApplication;
