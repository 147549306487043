import React from "react";

import SectionTitle from "../common/SectionTitle";

const FeatureImgContent = (props) => {
  return (
    <>
      <section className="why-choose-us pt-60 pb-120">
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 col-12">
              <div className="feature-img-holder my-4 my-lg-0 my-xl-0">
                <img
                  src={props.img}
                  className="img-fluid"
                  alt="feature"
                />
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="why-choose-content">
                <SectionTitle
                  title={props.title}
                  description={props.description}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContent;
