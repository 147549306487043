import React from "react";
import PageMeta from "../../components/common/PageMeta";
import MenuOne from "./MenuOne";
import MenuTwo from "./MenuTwo";
import MenuThree from "./MenuThree";
import CustomerLogo from "./CustomerLogo";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroEight from "./HeroEight";

const ProductAI = () => {
  return (
    <Layout>
      <PageMeta title="Sản phẩm AI" />
      <Navbar navDark />
      <HeroEight />
      <MenuOne />
      <MenuTwo />
      <MenuThree />
      <CustomerLogo />
      <FooterOne footerGradient />
    </Layout>
  );
};

export default ProductAI;
