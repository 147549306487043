import React from "react";
import { Link } from "react-router-dom";

const MainProduct = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top ${footerLight ? "footer-light" : "bg-dark"} ${
            footerGradient ? "bg-gradient" : ""
          }  text-white ptb-60`}
          style={style}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-8 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                <div className="footer-single-col">
                  <h3 className="mb-3" style={{ cursor: "context-menu" }}>
                    Phát triển ứng dụng phần mềm
                  </h3>
                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Phát triển app web và mobile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Công cụ tự động hóa quy trình
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Hệ thống giám sát website
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                <div className="footer-single-col">
                  <h3 className="mb-3" style={{ cursor: "context-menu" }}>
                    Phát triển hệ thống dữ liệu lớn
                  </h3>
                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Hệ thống báo cáo quản trị BI
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Hệ thống nền tảng dữ liệu
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Hệ thống thu thập dữ liệu
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-between pt-60">
              <div className="col-md-8 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                <div className="footer-single-col">
                  <h3 className="mb-3" style={{ cursor: "context-menu" }}>
                    <Link to="/product-ai" className="text-dark">Phát triển sản phẩm AI</Link>
                  </h3>
                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Sản phẩm xử lý hình ảnh
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Sản phẩm xử lý âm thanh
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Sản phẩm LLM, chatGPT
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                <div className="footer-single-col">
                  <h3 className="mb-3" style={{ cursor: "context-menu" }}>
                    Phát triển sản phẩm blockchain và NFT
                  </h3>
                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Game NFT
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product-and-solution"
                        className="text-decoration-none"
                      >
                        Smart Contract
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MainProduct;
