import React from "react";
import SectionTitle from "../common/SectionTitle";

const FeatureImgSeven = () => {
  return (
    <>
      <section className="why-choose-us pt-60 pb-120">
        <div className="container">
          <div className="row justify-content-lg-between justify-content-center align-items-center">
            <div className="col-lg-5 col-md-7 order-1 order-lg-0">
              <div className="why-choose-img position-relative">
                <img
                  src="assets/img/page-five/correcting-algorithm.jpg"
                  className="img-fluid"
                  alt="duel-phone"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 order-0 order-lg-1">
              <div className="why-choose-content">
                <div className="mb-32">
                  <SectionTitle
                    title="Thuật toán chính xác"
                    description="Giải pháp nhận diện biển số xe của Achitech sử dụng thuật toán học sâu (deep learning) đã được tối ưu cho việc phát hiện và nhận diện các biển số xe ở Việt Nam. Thuật toán có thể làm tốt trong những điều kiện gây nhiễu như ánh sáng, biển số mờ, góc chụp nghiêng. Giải pháp có thể được sử dụng trong nhiều ứng dụng khác nhau như kiểm soát phương tiện vào ra, tra cứu phương tiện dựa trên biển số xe… ."
                    leftAlign
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgSeven;
