import React from 'react';

const FeatureImg = () => {
  return (
    <>
      <section className='image-feature pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-between align-items-center'>
            <div className='col-lg-5 col-12 order-lg-1'>
              <div className='feature-img-content'>
                <h2>Tại sao chọn chúng tôi</h2>
                <p>
                Với những chuyên gia trong lĩnh vực tư vấn phát triển phần mềm web và app. Đồng thời tập trung chính vào ứng dụng AI, Data để giúp đỡ các doanh nghiệp phát triển đột phá.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-12 order-lg-0'>
              <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                <img
                  src='assets/img/home/why-choose-us.jpg'
                  className='img-fluid'
                  alt='feature-img'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImg;
