import React from "react";
import EventAbout from "../../components/events/EventAbout";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import PageMeta from "../../components/common/PageMeta";

const AboutUs = () => {
  return (
    <Layout>
      <PageMeta title="Về chúng tôi" />
      <Navbar />
      <EventAbout />
      <FooterOne />
    </Layout>
  );
};

export default AboutUs;
