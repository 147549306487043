import React from "react";

export default function DigiWorkProcess() {
  return (
    <section className="digi-how-works bg-white ptb-120 pb-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="digi-how-works-left">
              <div>
                <span className="span-arrow">Tại sao chọn chúng tôi</span>
                <img
                  src="assets/img/arro-right.svg"
                  className="img-fluid"
                  alt="arrow"
                />
              </div>
              <h2>Linh hoạt & dễ dàng tích hợp</h2>
              <p>
                Giải pháp linh hoạt, đáp ứng đa dạng nhu cầu của doanh nghiệp.
              </p>
              <p>Dễ dàng tích hợp.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="digi-how-works-right mt-5 mt-lg-0">
              <div className="dig-how-steps">
                <ul className="list-unstyled mb-0">
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    <div>
                      <h6>
                        1. Giải pháp linh hoạt, đáp ứng đa dạng nhu cầu của
                        doanh nghiệp
                      </h6>
                      <p className="m-0">
                        Giải pháp có thể linh hoạt điều chỉnh và tối ưu theo
                        mong muốn và thực trạng của doanh nghiệp. Dựa trên các
                        điều kiện như số lượng người cần định danh, môi trường
                        xung quanh,… mà chúng tôi sẽ tối ưu thuật toán phù hợp
                        nhất.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    <div>
                      <h6>2. Dễ dàng tích hợp</h6>
                      <p className="m-0">
                        Giải pháp có thể tích hợp mà không cần thêm phần cứng
                        của khách hàng mà sử dụng hoàn toàn hạ tầng (cloud) của
                        chúng tôi. Giải pháp cũng có thể được tích hợp trọn gói
                        trong hạ tầng của khách hàng.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="shape">
                  <ul className="list-unstyled mb-0">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
