import React from "react";
import BlogGrid from "../../components/blog/BlogGrid";
import PageHeader from "../../components/common/PageHeader";
import PageMeta from "../../components/common/PageMeta";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";

const Blogs = () => {
  return (
    <Layout>
      <PageMeta title="Blogs" />
      <Navbar navDark />
      <PageHeader
        title="Blogs"
        desc="Cập nhật những kiến thức, tech stack mới nhất trong các lĩnh vực như AI, Data."
        blogtags
      />
      <BlogGrid />
      <FooterOne />
    </Layout>
  );
};

export default Blogs;
