const IconBoxData = [
  {
    id: 1,
    classOption: "bg-primary",
    icon: "fal fa-layer-group fa-2x text-white",
    title: "Good Performance",
    description:
      "Appropriately grow competitive leadership rather than strategic technically sound processes without state .",
  },
  {
    id: 2,
    classOption: "bg-danger",
    icon: "fal fa-shield-check fa-2x text-white",
    title: "Highly Secure",
    description:
      "Appropriately grow competitive leadership rather than strategic technically sound processes without state.",
  },
  {
    id: 3,
    classOption: "bg-dark",
    icon: "fal fa-code fa-2x text-white",
    title: "Fast Development",
    description:
      "Appropriately grow competitive leadership rather than strategic technically sound processes without state.",
  },
];
const FaqOneData = [
  {
    id: 1,
    faqTitle: "How does back pricing work?",
    faqDesc:
      "Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain economically methods of empowerment for synergistic sound scenarios.",
  },
  {
    id: 2,
    faqTitle: "How do I calculate how much price?",
    faqDesc:
      "Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently maintain economically sound scenarios and whereas client-based progressively.",
  },
  {
    id: 3,
    faqTitle: "Can you show me an example?",
    faqDesc:
      "Dynamically visualize whereas competitive relationships. Progressively benchmark customized partnerships generate interdependent benefits rather sound scenarios and robust alignments.",
  },
];

const TestimonialData = [
  {
    id: 1,
    authorImg: "assets/img/comment/Phuoc_Nguyen.png",
    authorName: "Phuoc Nguyen",
    authorTitle: "Giám đốc kỹ thuật dự án Tabishow",
    authorQuote:
      "Việc lựa chọn Achitek là một trong những quyết định kinh doanh tốt nhất mà chúng tôi từng thực hiện. Sự tận tâm không ngừng nghỉ để đảm bảo sự hài lòng của khách hàng cùng với sự chú ý đến từng chi tiết một cách tỉ mỉ đã vượt qua cả mong đợi của chúng tôi. Đội ngũ của họ không chỉ giao hàng đúng hạn mà còn đảm bảo rằng mỗi khía cạnh của dự án đều đạt chuẩn mực của chúng tôi. Chúng tôi vô cùng hài lòng với kết quả và dịch vụ chuyên nghiệp mà họ cung cấp.",
  },
  {
    id: 2,
    authorImg: "assets/img/testimonial/2.jpg",
    authorName: "Duy Nguyen",
    authorTitle: "Giám đốc kỹ thuật công ty TNHH RightShift",
    authorQuote:
      "Việc hợp tác với Achitek đã mở ra những cơ hội mới cho doanh nghiệp chúng tôi. Sự chuyên nghiệp và sâu sắc trong việc xử lý và phân tích dữ liệu lớn mà họ mang lại đã vượt xa kỳ vọng của chúng tôi. Nhờ có kiến thức sâu rộng về các giải pháp dữ liệu, đội ngũ của họ đã giúp chúng tôi không chỉ cải thiện hiệu quả hoạt động mà còn mở rộng khả năng nhận thức sâu sắc về thị trường. Chúng tôi thực sự ấn tượng với sự nghiệp vụ cao và cảm kích trước dịch vụ hỗ trợ tuyệt vời mà họ cung cấp.",
  },
  // {
  //   id: 3,
  //   authorImg: "assets/img/testimonial/3.jpg",
  //   authorName: "Ranu Mondal",
  //   authorTitle: "Lead Developer",
  //   quoteTitle: "It is undeniably good!",
  //   authorQuote:
  //     "Rapidiously supply client-centric e-markets and maintainable processes progressively engineer",
  // },
  // {
  //   id: 4,
  //   authorImg: "assets/img/testimonial/4.jpg",
  //   authorName: "Mr.Rupan Oberoi",
  //   authorTitle: "Founder and CEO at Amaara Herbs",
  //   quoteTitle: "The Best Template You Got to Have it!",
  //   authorQuote:
  //     "Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical",
  // },
];

const TestimonialOneData = [
  {
    id: "#testimonial-tab-1",
    activeClass: "active",
    title: "The Best Template You Got to Have it!",
    desc: "Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.",
    authorName: "Joe Richard",
    authorDesn: "Visual Designer",
    authorThumb: "assets/img/testimonial/t-1.jpg",
    authorAvatar: "assets/img/testimonial/1.jpg",
  },
  {
    id: "#testimonial-tab-2",
    title: "Embarrassed by the First Version.",
    desc: "Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.",
    authorName: "Rupan Oberoi",
    authorDesn: "Web Designer",
    authorThumb: "assets/img/testimonial/t-2.jpg",
    authorAvatar: "assets/img/testimonial/2.jpg",
  },
  {
    id: "#testimonial-tab-3",
    title: "The Best Template You Got to Have it!",
    desc: "Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.",
    authorName: "Joe Richard",
    authorDesn: "Visual Designer",
    authorThumb: "assets/img/testimonial/t-3.jpg",
    authorAvatar: "assets/img/testimonial/3.jpg",
  },
  {
    id: "#testimonial-tab-4",
    title: "The Best Template You Got to Have it!",
    desc: "Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.",
    authorName: "Joe Richard",
    authorDesn: "Visual Designer",
    authorThumb: "assets/img/testimonial/t-4.jpg",
    authorAvatar: "assets/img/testimonial/4.jpg",
  },
  {
    id: "#testimonial-tab-5",
    title: "The Best Template You Got to Have it!",
    desc: "Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.",
    authorName: "Joe Richard",
    authorDesn: "Visual Designer",
    authorThumb: "assets/img/testimonial/t-5.jpg",
    authorAvatar: "assets/img/testimonial/5.jpg",
  },
];

export { IconBoxData, FaqOneData, TestimonialData, TestimonialOneData };
