import React from "react";

const ServicePromo = () => {
  return (
    <section className="services-icon ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-heading text-center">
              <h2>Tính năng</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end h-100">
              <div className="feature-icon icon-center pb-5 rounded-custom bg-dark">
                <i className="fa fa-camera-retro fa-2x text-white"></i>
              </div>
              <div className="service-info-wrap">
                <h3 className="h5">Phát hiện người lạ ra vào</h3>
                <p>
                  Tự động cảnh báo khi có người lạ xuất hiện trong các camera mà
                  không cần giám sát. Hệ thống sẽ tự đông cảnh báo bằng tin nhắn
                  hoặc gọi điện thoại.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end h-100">
              <div className="feature-icon icon-center pb-5 rounded-custom bg-primary">
                <i className="fa fa-eye fa-2x text-white"></i>
              </div>
              <div className="service-info-wrap">
                <h3 className="h5">Nhận diện khách hàng trung thành</h3>
                <p>
                  Tự động nhận diện khách hàng là khách hàng trung thành trong
                  tập cơ sở dữ liệu.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom h-100">
              <div className="feature-icon icon-center pb-5 rounded-custom bg-danger">
                <i className="fa fa-street-view fa-2x text-white"></i>
              </div>
              <div className="service-info-wrap">
                <h3 className="h5">Định danh khuôn mặt</h3>
                <p>
                  Tự dộng trích xuất thời gian ra vào của một người được đăng kí
                  trong cơ sở dữ liệu. Có thể tích hợp và sử dụng trong điểm
                  danh lớp học, phòng họp và chấm công trong doanh nghiệm.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicePromo;
