import React from "react";
import SectionTitle from "../common/SectionTitle";

const FeatureImgContentTwo = (props) => {
  return (
    <>
      <section className="feature-section pt-60 pb-120">
        <div className="container">
          <div className="row align-content-center justify-content-between">
            <div className="col-lg-5 col-md-12">
              <SectionTitle
                title={props.title}
                description={props.description}
              />
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="feature-shape-img position-relative mt-5 mt-lg-0">
                <img
                  src={props.img}
                  className="img-fluid skewed-img-right rounded-custom shadow-lg"
                  alt="softtent"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentTwo;
