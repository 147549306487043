import React from "react";
import { Link } from "react-router-dom";

export default function GameServices() {
  return (
    <section className="game-services ptb-120">
      <div className="game-service-bg pt-60 pb-60">
        <div className="container">
          <div className="row  align-items-center">
            <div className="col-lg-5 col-md-8">
              <div className="section-title mb-5 mb-lg-0">
                <h2 className="text-black mb-4 text-center">Các sản phẩm và giải pháp</h2>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="game-service-right">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row mb--200">
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4 p-3">
                          <h4 className="text-white fw-medium m-0 py-3">
                            Các ứng dụng phần mềm
                          </h4>
                          <p className="m-0 text-off-white">
                            Tư vấn, xây dựng ứng dụng web và mobile cho khách
                            hàng sử dụng công nghệ web mới nhất. Bên cạnh đó,
                            chúng tôi cũng tư vấn và cung cấp các giải pháp tự
                            động hoá quy trình làm việc cho các doanh nghiệp.
                          </p>
                          <div className="d-flex justify-content-evenly">
                            <Link
                              to="/"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Xem chi tiết
                            </Link>
                            <Link
                              to="/contact-us"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Liên hệ
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4 p-3">
                          <h4 className="text-white fw-medium m-0 py-3">
                            Giải pháp dữ liệu lớn
                          </h4>
                          <p className="m-0 text-off-white">
                            Tư vấn và xây dựng hệ thống báo cáo quản trị (BI) để
                            cung cấp cái nhìn tổng thể về doanh nghiệp. Chúng
                            tôi cũng cung cấp các giải pháp nền tảng dữ liệu
                            (data platform) và giải pháp chuyển đổi dữ liệu
                            (data migration).
                          </p>
                          <div className="d-flex justify-content-evenly">
                            <Link
                              to="/"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Xem chi tiết
                            </Link>
                            <Link
                              to="/contact-us"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Liên hệ
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row mt--200">
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4 p-3">
                          <h4 className="text-white fw-medium m-0 py-3">
                            Các sản phẩm AI
                          </h4>
                          <p className="m-0 text-off-white">
                            Tư vấn và phát triển các sản phẩm AI dựa trên nhu
                            cầu của doanh nghiệp. Các sản phẩm liên quan đến xử
                            lý hình ảnh, xử lý âm thanh và chatGPT.
                          </p>
                          <div className="d-flex justify-content-evenly">
                            <Link
                              to="/"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Xem chi tiết
                            </Link>
                            <Link
                              to="/contact-us"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Liên hệ
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4 p-3">
                          <h4 className="text-white fw-medium m-0 py-3">
                            Các sản phẩm blockchain và NFT
                          </h4>
                          <p className="m-0 text-off-white">
                            Tư vấn và xây dựng các sản phẩm sử dụng nền tảng
                            blockchain, các game NFT.
                          </p>
                          <div className="d-flex justify-content-evenly">
                            <Link
                              to="/"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Xem chi tiết
                            </Link>
                            <Link
                              to="/contact-us"
                              className="btn btn-primary px-1 py-1 mt-4"
                            >
                              Liên hệ
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
