import React from 'react'
import PageMeta from "../../components/common/PageMeta";
import ServicePromo from "../../components/promo/ServicePromo";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroNine from "./HeroNine";
import DigiWorkProcess from '../../components/work-process/DigiWorkProcess';
import NewsLetterTwo from '../../components/cta/NewsLetterTwo';

const HomeNine = () => {
  return (
    <Layout>
      <PageMeta title="Nhận diện khuôn mặt" />
      <Navbar />
      <HeroNine />
      <NewsLetterTwo />
      <DigiWorkProcess />
      <ServicePromo />
      <FooterOne />
    </Layout>
  );
};

export default HomeNine;
